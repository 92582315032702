








































import { Component } from 'vue-property-decorator';
import TableDialogFormComponent from '@common-src/mixins/table-dialog-form-component';
import { SubsystemEntityModel, SubsystemQueryModel } from '@common-src/entity-model/subsystem-entity';
import SubsystemService from '@common-src/service/subsystem';

@Component
export default class SubsystemListComponent extends TableDialogFormComponent<SubsystemEntityModel, SubsystemQueryModel> {
    SubsystemEntityModel = SubsystemEntityModel;
    created() {
        this.initTable({
            service: new SubsystemService(),
            queryModel: new SubsystemQueryModel(),
            tableColumns: SubsystemEntityModel.getTableColumns()
        });
        this.getList();
    }
}

